<template>
  <div id="login">
    <header class="header header1 clearfix"
            id="header">
      <top-nav />
    </header>
    <div class="login">
      <div v-show="isChanges"
           class="login-box">
        <div class="pos"
             v-loading="loading">
          <h1 class="adminh1">{{$t('login.bbzn')}}</h1>
          <el-form :model="ruleForm"
                   status-icon
                   :rules="rules"
                   ref="ruleForm"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item :label="$t('login.sjh')"
                          prop="pass">
              <el-input prefix-icon="el-icon-user"
                        style="width: 350px"
                        type="text"
                        v-model="ruleForm.pass"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.mm')"
                          prop="checkPass">
              <el-input prefix-icon="el-icon-menu"
                        style="width: 350px"
                        show-password
                        type="password"
                        v-model="ruleForm.checkPass"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <p @click="registered"
                 class="registered">{{$t('login.zczh')}}</p>
            </el-form-item>
            <el-form-item>
              <el-button type="goon"
                         @click="submitForm('ruleForm')">{{$t('login.dl')}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="login-box"
           v-show="!isChanges">
        <div class="pos"
             v-loading="loading">
          <h1 class="adminh1">{{$t('login.zczh')}}</h1>
          <div class="return"
               @click="isChanges = true">{{$t('login.qdl')}}</div>
          <el-form :model="regist"
                   status-icon
                   :rules="ruless"
                   ref="regist"
                   label-width="100px">
            <el-form-item :label="$t('login.zh')"
                          prop="userName">
              <el-input v-model="regist.userName"
                        :placeholder="$t('login.qsrzh')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.mm')"
                          prop="userPassword">
              <el-input v-model="regist.userPassword"
                        show-password
                        :placeholder="$t('login.qsrmm')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.qrmm')"
                          prop="userAgainPassword">
              <el-input v-model="regist.userAgainPassword"
                        show-password
                        :placeholder="$t('login.qzcsrmm')">
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.sjh')"
                          prop="userPhone">
              <el-input v-model="regist.userPhone"
                        :placeholder="$t('login.qsrsjh')">
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.yzm')"
                          prop="messageContent">
              <div class="getCode">
                <el-input style="width: 850px; margin-right: 20px"
                          v-model="regist.messageContent"
                          :placeholder="$t('login.qsryzm')"></el-input>
                <getCode @again="userPhoneAgain"
                         @getCode="registerGetCode"
                         :isHave="phoneIsHave"
                         :disabled="regist.disabled" />
              </div>
            </el-form-item>
            <el-form-item label=" "
                          prop="name">
              <el-button type="danger"
                         class="registButton"
                         @click="register('regist')"
                         round>{{$t('login.zc')}}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getCode from "../components/common/getCode.vue";
import TopNav from "../components/common/topNav.vue";
export default {
  name: "app",
  components: { getCode, TopNav },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('login.qsrsjh')));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('login.qsrmm')));
      } else if (value.length < 6 || value.length > 13) {
        callback(new Error(this.$t('login.cd')));
      } else {
        callback();
      }
    };
    let userPhone = (rule, value, callback) => {
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        callback(this.$t('login.qsrzqdsjhm'));
        this.regist.disabled = true;
      } else {
        callback();
        if (this.regist.phoneDisabledNum == 0) {
          this.regist.disabled = false;
        }
      }
    };
    let userPassword = (rule, value, callback) => {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/; //6-20位，至少带字母数字符号中的两种的正则
      if (!reg.test(value)) {
        callback(this.$t('login.mmtj'));
      } else {
        callback();
      }
    };
    let againPassword = (rule, value, callback) => {
      if (value === this.regist.userPassword) {
        callback();
      } else {
        callback(this.$t('login.mmbyz'));
      }
    };
    return {
      loading: false,
      phoneIsHave: false,
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      regist: {
        userName: "",
        userPassword: "",
        userAgainPassword: "",
        userPhone: "",
        messageContent: "",
        // userEmail: '',
        emailContent: "",
        audio: false,
        disabled: true,
        phoneDisabledNum: 0,
        emailDisabled: true,
        emailDisabledNum: 0,
      },
      ruless: {
        userName: [{ required: true, message: this.$t('login.qsrzh'), trigger: "blur" }],
        userPassword: [
          { required: true, message: this.$t('login.qsrmm'), trigger: "blur" },
          { validator: userPassword, trigger: "blur" },
        ],
        userAgainPassword: [
          { required: true, message: this.$t('login.qzcsrmm'), trigger: "blur" },
          { validator: againPassword, trigger: "blur" },
        ],
        userPhone: [
          { required: true, message: this.$t('login.qsrsjh'), trigger: "blur" },
          { validator: userPhone, trigger: "change" },
        ],
        messageContent: [
          { required: true, message: this.$t('login.qsryzm'), trigger: "blur" },
          // {validator: messageContent, trigger: 'blur'}
        ],
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      isChanges: true,
    };
  },
  mounted () {
    // console.log(!!this.$route.params.reg);
    // this.isChanges = !!this.$route.params.reg ? false : true;
  },
  methods: {
    async login () {
      let params = {
        id: "1",
        username: this.ruleForm.pass,
        password: this.ruleForm.checkPass,
      };
      let res = await this.api.post(this.apiPath.login, params);
      if (res.token) {
        localStorage.setItem("params", JSON.stringify(params));
        localStorage.setItem("userMap", JSON.stringify(res.userMap));
        localStorage.setItem("token", res.token);
        this.$router.go(-1);
        this.loading = false;
      } else {
        this.$message.error(res.msg);
        this.loading = false;
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    userPhoneAgain () {
      this.regist.disabled = false;
      this.regist.phoneDisabledNum = 0;
    },
    async registerGetCode () {
      //获取手机验证码
      let params = {
        phone: this.regist.userPhone,
      };
      let res = await this.api.post(this.apiPath.verificationCode, params);
      this.regist.disabled = true;
      if (res.code == 0) {
        this.phoneIsHave = true;
        // this.$message(res.msg);
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    registered () {
      this.isChanges = false;
    },
    //注册
    register (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.regist);
          this.registerInterface();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async registerInterface () {
      // 注册接口
      let params = {
        language: "CN",
        username: this.regist.userName,
        password: this.regist.userPassword,
        phone: this.regist.userPhone,
        smscode: this.regist.messageContent,
      };
      let res = await this.api.post(this.apiPath.registered, params);
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.isChanges = true;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  background-image: url("../assets/login/loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: initial;
  background-position: center;
  width: 100%;
  height: 100%;
}
#login {
  width: 100%;
  height: 1329px;
}
.top {
  width: 100%;
  height: 100px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 20%;
  img {
    height: 60%;
  }
  p {
    font-size: 25px;
    color: #7f0114;
    margin-left: 20px;
  }
}
.login-box {
  width: 500px;
  height: 420px;
  background: #eee;
  margin: auto;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10%;
  margin-left: 5%;
  width: 600px;
  height: 480px;
  background: #eee;
  border-radius: 10%;
  box-shadow: 8px 10px 10px #a57e84;
}
.adminh1 {
  font-size: 22px;
  margin: 20px 0;
  text-shadow: 10px 13px 3px #a57e84;
  text-align: center;
}
.pos {
  width: 550px;
  height: 400px;
  position: absolute;
  top: 25px;
  left: 25px;
  position: relative;
  .return {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 18px;
  }
  .return:hover {
    color: #7f0114;
    cursor: pointer;
  }
}
.el-form {
  margin-top: 40px;
}
.el-button--goon {
  width: 350px;
  color: #fff;
  background-color: #7f0114;
  border-color: #7f0114;
  height: 44px;
  line-height: 34px;
  padding: 0 20px;
  margin-right: 100px;
}
.el-button--goon:focus,
.el-button--goon:hover {
  color: #fff;
  background-color: #813f49;
  border-color: #813f49;
  height: 44px;
  line-height: 34px;
  padding: 0 20px;
}
.registered {
  float: right;
  margin-right: 100px;
  cursor: pointer;
}
.registered:hover {
  color: #7f0114;
}
.getCode {
  display: flex;
}
.registButton {
  width: 93%;
}
.header {
  height: 45px;
}
@media screen and (max-width: 750px) {
  .login-box {
    left: 0;
    top: 20%;
    width: 100%;
    margin-left: 0;
    .pos {
      width: 90%;
    }
  }
  .el-button--goon {
    width: 200px;
  }
  .el-input {
    width: 200px !important;
  }
}
</style>
