// 中文
module.exports = {
  banner: {
    ljlj: '立即了解',
  },
  header: {
    bbzn: '把把物联',
    hyn: '欢迎您！',
    wddd: '我的订单',
    gwc: '购物车',
    ptdl: '平台登录',
    qdl: '请登录',
    tcdl: '退出登录',
    dhl: '导航栏'
  },
  nav: {
    bbwh: "把把文化",
    home: "首页",
    flag: "{who}名字是{what}",
    zhcs: '智慧城市',
    zhgc: '智慧工厂',
    zhxf: '智慧消防',
    zhgd: '智慧工地',
    zhsq: '智慧社区',
    zhxy: '智慧校园',
    bbdt: '把把动态',
    zwcx: "真伪查询",
    zlxz: "资料下载",
    rjxz: "软件下载",
    gysly: "供应商留言",
    khly: "客户留言",
    bbjj: "把把简介",
    ryzz: "荣誉资质",
    lxwm: "联系我们",
    hydt: '行业动态',
    bbbk: '把把百科',
    cpzx: '产品中心',
    jjfa: '解决方案',
    xmal: '项目案例',
    bbzx: '把把资讯',
    fwzc: '服务支持',
    zsdl: '招商代理',
    gybb: '关于把把',
    zxsp: '在线视频',
    hylt: '行业论坛',
    yhxy: "用户协议",
    ystk: "隐私条款",
    fhtkzc: "发货退款政策",
  },
  product: {
    ljgm: '查看参数',
    cpzxsy: '产品中心首页',
    znaqm: '智能安全帽',
    ywbjq: '烟雾报警器',
    dwsb: '定位手表',
    zhyd: '智慧用电',
    znsb: '智能水表',
    znpt: '智能平台',
    yyfc: '越野房车',
    xfcl: '消防车辆',
    jksb: '监控设备',
    ss: '搜索',
    ljlj: '立即了解'
  },
  footer: {

  },
  solution: {
    ckgd: '查看更多'
  },
  common: {
    sy: '首页',


  },
  breadcrumb: {
    xwdt: '新闻动态',
    bbdt: '把把动态',
    hydt: '行业动态',
    bbbk: '把把百科',
    ljxq: '了解详情'
  },
  serve: {
    fwzc: '服务支持',
    zlxz: '资料下载',
    rjxz: '软件下载',
    yhxy: "用户协议",
    ystk: "隐私条款",
    fhtkzc: "发货退款政策",
    gysly: '供应商留言',
    zwcx: '真伪查询',
    zwcx_placeholder: '请输入产品imei',
    cpxx: "产品信息",
    pcy: "品质保证 / 超长质保 / 运转无忧",
    cpxh: "产品型号",
    ys: "颜色",
    sbh: "设备号",
    ccrq: "出产日期",
    sccj: "生产厂家",
    zjy: '质检员',
    xsryxm: "销售人员姓名",
    dlsmc: "代理商名称",
    gwrk: "官网入口"
  },
  goodsDetail: {
    price: '价格',
    number: '数量',
    addgoodscat: '加入购物车',
    buynow: '立即购买'
  },
  safetyHat: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  fireEngine: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  platForm: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  smartElectricity: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  smokeDetector: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  touringCar: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  watch: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  waterMeter: {
    cpzxsy: '产品中心首页',
    ss: '搜索',
    buynow: '查看参数'
  },
  dynamic: {
    home: "首页",
    xwdt: '新闻动态'
  },
  dynamicDetail: {
    zz: "作者"
  },
  dynamicBox: {
    ljxq: '了解详情'
  },
  supplier: {
    qymc: '企业名称',
    qcrqymc: '请输入企业名称',
    xm: "姓名",
    qsrxm: "请输入姓名",
    yx: '邮箱',
    qsuyx: '请输入邮箱',
    dh: '电话',
    qsrdh: "请输入电话",
    nr: '内容',
    qsrnr: "请输入内容",
    tj: "提交",
    cz: '重置',
    qtxzqddhhm: '请填写正确的电话号码',
    qtxzqdyx: '请填写正确的邮箱',
    qsrqymc: '请输入企业名称',
    qsrsjh: "请输入手机号",
  },
  agentForm: {
    xm: "姓名",
    qsrxm: "请输入姓名",
    yx: '邮箱',
    qsuyx: '请输入邮箱',
    dh: '电话',
    qsrdh: "请输入电话",
    nr: '内容',
    qsrnr: "请输入内容",
    tj: "提交",
    cz: '重置',
    qtxzqddhhm: '请填写正确的电话号码',
    qtxzqdyx: '请填写正确的邮箱',
    qsrqymc: '请输入企业名称',
    qsrsjh: "请输入手机号",
  },
  about: {
    dh: '电话',
    yx: '邮件',
    zbgsdz: '总部公司地址',
    zbgsmc:'总部公司名称',
    fbgsmc:'分部公司名称',
    fbgsdz:'分部公司地址'
  },
  footerContent: {
    jzhxz: '佳智惠下载',
    qgzxfwrx: "全国咨询服务热线",
    gsdz: "公司地址",
    gsdzvalue: "深圳市宝安区石岩街道塘头社区塘头南岗第三工业园11栋四层",
    gzwxgzh: "关注微信公众号",
    gzwb: "关注微博",
    wxgzh: "微信公众号",
    APPxz: "APP下载",
    bq: "深圳把把物联科技有限公司版权所有",
    xz: "把把APP下载",
    znsfcp: "智能司法产品",
    znxfcp: '智能消防产品',
    znafcp: '智能安防产品',
    znshcp: '智能生活产品',
    znpj: '智能配件',
    znglpt: '智能管理平台',
    zhcs: '智慧城市',
    zhgc: '智慧工厂',
    zhxf: '智慧消防',
    zhgd: '智慧工地',
    zhsq: '智慧社区',
    zhxy: '智慧校园',
    jjfa: '解决方案',
    cpzxsy: '产品中心首页',
    bbzx: '把把资讯',
    bbdt: '把把动态',
    hydt: '行业动态',
    bbbk: '把把百科',
    fwzc: '服务支持',
    bbjj: "把把简介",
    ryzz: "荣誉资质",
    gybb: '关于把把',
    bbwh: "把把文化",
    lxwm: "联系我们",
    hzhb: "合作伙伴",
    zlxz: '资料下载',
    rjxz: '软件下载',
    gysly: '供应商留言',
    zwcx: '真伪查询',
    khly: "客户留言",
     yhxy: "用户协议",
    ystk: "隐私条款",
    fhtkzc: "发货退款政策",
  },
  login: {
    bbzn: "把把物联",
    sjh: '手机号',
    mm: '密码',
    zczh: "注册账号",
    dl: '登录',
    qdl: "请登录",
    zc: '注册',
    zh: "账号",
    qsrzh: '请输入账号',
    qsrmm: '请输入密码',
    qrmm: '确认密码',
    qzcsrmm: "请再次输入密码",
    qsrsjh: "请输入手机号",
    yzm: "验证码",
    qsryzm: "请输入验证码",
    cd: "长度必须在6-12位之间",
    qtxzqdsjhm: "请填写正确的手机号码",
    mmtj: "密码必须包含数字和字母，6-20位",
    mmbyz: '密码不一致',
    hqyzm: "获取验证码",
    get: '后重新获取'
  },
  logisticsInfo: {
    wlxq: '物流详情',
    ydhm: '运单号码',
    wlgs: '物流公司',
    kfdh: '客服电话',
    wlgsgfwz: '物流公司官方网站',
    zwwlxx: '暂无物流信息'
  },
  MyAddress: {
    shdz: '收货地址',
    tjxdz: "添加新地址",
    mr: "默认",
    xg: "修改",
    sc: '删除',
    shr: '收货人',
    shdq: '收货地区',
    xxdz: '详细地址',
    sjhm: '手机号码',
    scdzcg: '删除地址成功',
    xz: '选择',
    dzbm: '地址别名',
    mrdz: '默认地址',
    qsrsjrxm: '请输入收件人姓名',
    qsrdz: '请输入地址',
    qsrxxdz: '请输入详细地址',
    sjhbnwk: '手机号不能为空',
    sjhgscc: "手机号格式出错",
    xgdzcg: '修改地址成功',
    xinz: '新增',
    tjdzcg: '添加地址成功'

  },
  MyOrder: {
    dd: '订单',
    qbdd: '全部订单',
    dfk: "待付款",
    dfh: '待发货',
    dsh: '待收货',
    ywc: '已完成',
    my: '每页',
    tiao: '条'

  },
  orderDetail: {
    ddxq: '订单详情',
    ddh: '订单号',
    cjsj: '创建时间',
    shrx: '收货人信息',
    shr: '收货人',
    sjhm: '手机号码',
    shdz: '收货地址',
    cptp: '产品图片',
    cpmc: '产品名称',
    dj: '单价',
    sl: '数量',

  },
  pay: {
    shrxx: '收货人信息',
    glshrdz: "管理收货人地址",
    xg: "修改",
    sc: '删除',
    tjxdz: '添加新地址',
    sqdz: '收起地址',
    gddz: '更多地址',
    spxx: "商品信息",
    fhgwc: '返回购物车',
    yh: '有货',
    wu: '无货',
    ddbz: '订单备注',
    ts: '提示：请勿填写有关支付、收货方面的信息,',
    tjdd: '提交订单',
    psz: '配送至',
    shdz: '收货地址',
    shr: '收货人',
    zffs:'支付方式',
    qxzndzffs:'请选择您的支付方式',
    jnzf:'境内支付',
    jwzf:'境外支付',
    qx:'取消',
    qr:'确认'

  },
  payment: {
    ddtjcg: '订单提交成功，请尽快付款！',
    qnjkwczf: '请您尽快完成支付，否则订单会被自动取消',
    dycsqx: '订单已超时取消',
    yfje: '应付金额',
    yuan: '元',
    qsywxsmzf: '请使用微信扫码支付',
    zfcghzdtz: '支付成功后自动跳转，如为跳转请点击按钮手动跳转',
    cxzf: '重新支付',
    zfb: '支付宝',
    wx: '微信',
    zfcg: '支付成功'
  },
  shopping: {
    tp: '图片',
    spmc: '商品名称',
    dj: '单价',
    xj: '小计',
    sl: '数量',
    cz: "操作",
    jxll: '继续浏览',
    gong: '共',
    jcp: '件产品',
    hj: '合计',
    qjs: '去结算',
    cczjycgsp: '此操作将移除该商品，是否继续？',
    ts: '提示',
    qr: '确认',
    qx: '取消',
    yqxyc: '已取消移除',
    ycspcg: '移除商品成功',
    xgslcg: '修改数量成功',
    qxznyjsdsp: '请选择您要结算的商品',

  },
  menu: {
    gwc: '购物车',
    ddzx: '订单中心',
    wddd: '我的订单',
    shdz: '收货地址',

  },
  noLogin: {
    ljzf: '立即支付',
    qxss: '全新上市',
    sl: '数量',
    yfje: '应付金额',
    gmxx: '购买信息',
    shxx: '收货信息',
    shr: '收货人',
    qsrndzwmhywm: '请输入您的中文名或英文名',
    shdz: '收货地址',
    qsrndshdz: '请输入您的收货地址',
    lxdh: '联系电话',
    qsrndlxfs: '请输入您的联系方式',
    yzm: '验证码',
    qsryzm: '请输入验证码',
    qsrzqdsjh: '请输入正确的手机号',
    zfb: '支付宝',
    wx: '微信',
    gsjhybzy: '该手机号已被占用',
    gsjhmyzcqdl: '该手机号码已注册，请登陆'
  },
  home: {
    tjcp: '推荐产品',
    ckgd: '查看更多',
    jjfa: '解决方案',
    bbznglpt: '把把物联管理平台',
    xmal: '项目案例',
    zlhzhb: '战略合作伙伴',
    yljh: '友情链接',
    bbscrk: '把把商城入口',
    bbptdl: '把把平台登录',
    gysrk: '供应商入口',
    hzhbrk: '合作伙伴入口',
    zsjmrk: '招商加盟入口',

  },
  orderBox: {
    ddh: '订单号',
    cjsj: '创建时间',
    ddxq: '订单详情',
    dzf: '待支付',
    sdgb: '手动关闭',
    qxdd: '取消订单',
    wzf: '未支付',
    chgb: '超时关闭',
    zfsb: '支付失败',
    qzf: '去支付',
    ckcg: '出库成功',
    ywc: '已完成',
    kcwl: '查看物流',
    qrsh: '确认收货',
    qxzqxddyy: '请选择取消订单原因',
    qx: '取消',
    qr: '确认',
    qrgb: '确认关闭',
    qxddcg: '取消订单成功',
    nqryshm: '你确认要收货吗？',
    ts: '提示',
    yqxsh: '已取消收货',
    wldt:'物流动态',
    sqsh:'申请售后',
    shfw:'售后服务',
    qxzshfw:'请选择售后服务',
    gzjj:'故障解决',
    hh:'换货',
    thtk:'退货退款',
    tkbth:'退款不退货'
  }

}
